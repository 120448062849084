export const isDev = process.env.NODE_ENV === "development";

export function urlToList(url) {
  if (!url || url === '/') {
    return ['/'];
  }

  var urlList = url.split('/').filter(function (i) {
    return i;
  });
  return urlList.map(function (urlItem, index) {
    return "/".concat(urlList.slice(0, index + 1).join('/'));
  });
}

export const coverageColor = (lineRate) => {
  if (lineRate === 0) return 'black';
  if (lineRate <= 0.6) return 'red';
  if (lineRate < 0.80) return 'orange';
  if (lineRate < 0.90) return 'yellow';
  if (lineRate >= 0.90)  return 'green';
  return 'black';
}

export const coverageColorHex = (lineRate) => {
  if (lineRate === 0) return '#151412';
  if (lineRate <= 0.6) return '#c72929';
  if (lineRate < 0.80) return '#f29040';
  if (lineRate < 0.90) return '#f4d10b';
  if (lineRate >= 0.90)  return '#45d298';
  return '#151412';

}

export const colorForBranch = (branch) => {
  if(branch === "master") return "green";
  if (branch === "develop") return "magenta";
  if (branch === "staging") return "blue";

  return "magenta";
};

export const colorForStatus = (status) => {
  if (status === "passed") return "green";
  if (status === "failed") return "red";
  if (status === "pending") return "orange";
  return "black";
}